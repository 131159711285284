body {
  background-image: url('/public/images/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.book-item {
  cursor: pointer;
  margin-bottom: 25px;
}

.book-item article {
  display: block;
  background-color: #eeeeee;
  padding: 10px;
  margin: 5px;
  min-height: 200px;
  max-height: 200px;
  height: 200px;
  margin-top: 30px;
}

.book-item article:hover {
  background-color: #efefef !important;
}

figure {
  display: block;
  float: left;
  padding-right: 20px;
  height: 180px;
  min-height: 180px;
  max-height: 180px;
}

figure img {
  width: auto;
  height: 180px;
  min-height: 180px;
  max-height: 180px;
}

p {
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.book-item article h4 {
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  min-height: 100px;
}

button {
  margin-top: 20px;
}

.pagination-left {
  width: 50px !important;
  height: 50px;
  position: fixed;
  left: 0;
  top: 50%;
  z-index: 999;
  background-color: #efefef;
  border: solid 1px white;
  border-radius: 90px;
  font-size: 2rem;
  margin: 0;
  padding: 0;
}

.pagination-right {
  width: 50px !important;
  height: 50px;
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 999;
  background-color: #efefef;
  border: solid 1px white;
  border-radius: 90px;
  font-size: 2rem;
  margin: 0;
  padding: 0;
}

.search {
  margin-top: 40px !important;
}

label {
  color: white;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 20px;
}

.badge {
  font-weight: 600;
  font-size: 1rem !important;
}

.right-text {
  float: right;
}

.whitebg {
  background-color: rgba(255,255,255,0.8);
  border-radius: 8px;
  border: solid 1px #efefef;
  padding: 16px;
}

.footerbg {
  background-color: rgba(255,255,255,0.8);
  margin-top: 16px !important;
  border-radius: 8px !important;
  padding: 16px !important;
}

.footer-menu {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0px;
}

.footer-link {
  display: inline-block;
  list-style: none;
  vertical-align: middle;
  justify-content: center;
}

.footer-link a {
  color: black;
  font-weight: 600;
  font-size: 16px;
  padding: 16px;
  text-decoration: none;
}

.footer-link a:hover {
  color: black;
  font-weight: 600;
  font-size: 16px;
  padding: 16px;
  text-decoration: underline;
}

.gdpr-block {
  display: block;
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 70px;
  padding: 16px;
  background-color: rgba(255,255,255,1);
  text-align: center;
  box-shadow: 0px -8px 8px -8px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px -8px 8px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px -8px 8px -8px rgba(0,0,0,0.75);
}

.accept-button {
  float: right;
}

.year18Lock {
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.8);
  color: white;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  padding-top: 20%;

  .warningtext {
    padding-bottom: 10%;
    color: white;
    font-size: 32px;
    font-weight: 600;
    text-align: center;  
  }
}